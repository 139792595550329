export enum SignOnArticleKeys {
  customerId = 'customerId',
  countArticles = 'countArticles',
  signOnArticle = 'signOnArticle',
  signOnDeviceType = 'signOnDeviceType',
  signOnReferrer = 'signOnReferrer',
  signUpArticleHeadline = 'signUpArticleHeadline',
  signUpArticleImageUrl = 'signUpArticleImageUrl',
  signUpArticlePublishedDate = 'signUpArticlePublishedDate',
  signUpArticleAuthors = 'signUpArticleAuthors',
  signUpArticleSection = 'signUpArticleSection'
}

export enum NewsletterActiveFlag {
  Active = 'Y',
  Inactive = 'N'
}

export interface Newsletter {
  newsletterName: string
  newsletterOptinDate: string
  newsletterActiveFlag: NewsletterActiveFlag
  openCount: number | null
  clickCount: number | null
}

export interface MostRead {
  type: string
  value: string
  views: number
}

export enum ChurnBuckets {
  NoEarlyLifeData = 'NO EARLY LIFE DATA',
  NoChurnData = 'NO CHURN DATA',
  BaseLow = 'BASE LOW',
  Medium = 'MEDIUM',
  High = 'HIGH',
  Higher = 'HIGHER',
  Highest = 'HIGHEST',
  Top = 'TOP'
}

export type CustomerStats = {
  articlesRead: number
  comments: number
  puzzles: number
  tenureMonths: number
  mpCode: string
  appDownloadedFlag: boolean
  timesPlusRedemptionFlag: boolean
  hasTrialFlag: boolean
  newsletters: Newsletter[]
  mostRead: MostRead[]
  priceMigrationFlag: boolean
  priceIncrease: boolean
  notificationDate: string
  migrationDate: string
  saveTools: string
  newMpCode: string
  newPackName: string
  newPrice: string
  churnBuckets: ChurnBuckets | ''
  churnSegmentExplanation: string
  [SignOnArticleKeys.customerId]: string
  [SignOnArticleKeys.countArticles]: number
  [SignOnArticleKeys.signOnArticle]: string
  [SignOnArticleKeys.signOnReferrer]: string
  [SignOnArticleKeys.signOnDeviceType]: string
  [SignOnArticleKeys.signUpArticleHeadline]: string
  [SignOnArticleKeys.signUpArticleImageUrl]: string
  [SignOnArticleKeys.signUpArticlePublishedDate]: string
  [SignOnArticleKeys.signUpArticleAuthors]: string
  [SignOnArticleKeys.signUpArticleSection]: string
}

export type CustomerDailySession = {
  activityDate: string
  sessionsPerDay: string
}

export type TimesPlusOffer = {
  name: string
  type: string
  endDate: string
}

export type TimesPlusRedemption = {
  name: string
  type: string
  redemptionDate: string
  redemptionAction: string
}

export enum DeviceType {
  PLATFORM = 'platform',
  DEVICE = 'device'
}

export interface DeviceUsage {
  name: string
  type: DeviceType
  count: number
}

export interface DeviceUsageByType {
  [DeviceType.DEVICE]: DeviceUsage[]
  [DeviceType.PLATFORM]: DeviceUsage[]
}
export interface UserInfoStateType {
  customerStats: CustomerStats
  customerDailySessions: CustomerDailySession[]
  timesPlusOffers: TimesPlusOffer[]
  timesPlusRedemption: TimesPlusRedemption[]
  deviceUsage: {
    [DeviceType.PLATFORM]: DeviceUsage[]
    [DeviceType.DEVICE]: DeviceUsage[]
  }
}
