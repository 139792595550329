import { DeviceType, UserInfoStateType } from 'redux/reducers/userInfo/types'

export const UserInfoInitialState: UserInfoStateType = {
  customerStats: {
    articlesRead: 0,
    comments: 0,
    puzzles: 0,
    tenureMonths: 0,
    mpCode: '',
    appDownloadedFlag: false,
    timesPlusRedemptionFlag: false,
    hasTrialFlag: false,
    newsletters: [],
    mostRead: [],
    priceMigrationFlag: false,
    priceIncrease: false,
    notificationDate: '',
    migrationDate: '',
    newMpCode: '',
    newPackName: '',
    saveTools: '',
    newPrice: '',
    churnBuckets: '',
    churnSegmentExplanation: '',
    customerId: '',
    countArticles: 0,
    signOnArticle: '',
    signOnReferrer: '',
    signOnDeviceType: '',
    signUpArticleHeadline: '',
    signUpArticleImageUrl: '',
    signUpArticlePublishedDate: '',
    signUpArticleAuthors: '',
    signUpArticleSection: ''
  },
  customerDailySessions: [],
  timesPlusOffers: [],
  timesPlusRedemption: [],
  deviceUsage: {
    [DeviceType.PLATFORM]: [],
    [DeviceType.DEVICE]: []
  }
}
